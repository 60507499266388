@media print {
  @page {
    size: landscape;
  }

  .no-print {
    display: none !important;
  }

  .col-print {
    grid-column: span 2 / span 2;
  }

  .grid-print {
    grid-template-columns: repeat(6, minmax(0, 1fr));
    grid-column: span 6 / span 6;
  }

  .col-span-print {
    grid-column: span 6 / span 6;
  }
}

@page {
  size: auto;
  margin: 10mm;
}
